@charset "utf-8";
/* CSS Document */

body {
	background-color: #fff;
	font-family: 'Lato', sans-serif;
	font-size: 18px;
	color: #4f4e56;
	/*-webkit-transform: translateZ(0); 
	transform: translateZ(0);*/
	overflow-x: hidden;
}

.container {
	background-color: #fff;	
}

h1 {
	font-size: 30px;
	text-transform: uppercase;
	padding: 0px;
	margin: 0px 0px 15px 0px;
	font-weight: bold;
	text-align: center;	
}

h2 {
	font-size: 20px;
	text-transform: uppercase;
	padding: 0px;
	margin: 0px 0px 25px 0px;
	text-align: center;	
}

h3 {
	font-size: 20px;
	text-transform: uppercase;
	padding: 0px;
	margin: 0px 0px 35px 0px;
	text-align: center;	
	font-weight: bold;
}

.circle {
	padding: 0px 0px 40px 0px;	
}

.blue-link {
	text-align: center;
}

.blue-link a {
	display: inline-block;
	background-color: #007ac2;
	color: #fff!important;
	text-transform: uppercase;
	font-size: 20px;
	padding: 7px 60px 7px 60px;
	margin:  80px 0px 0px 0px;
}

.blue-link a:hover {
	text-decoration: none!important;
	background-color: #004b91;	
}

#logo {
	padding: 30px 0px 15px 50px;	
	/*background: #ffffff;*/
}

#logo img {
	display: inline-block;
}

#nav {
	padding: 70px 50px 0px 0px; 
	text-align: right; 
	font-size: 15px;
	text-transform: uppercase;
}	

#nav ul {
	list-style: none;
	margin: 0px;
	padding: 0px;	
}

#nav ul li {
	display: inline;
	padding: 0px 0px 0px 15px;	
	position: relative;
}

#nav a {
	color: #ffffff;
	text-decoration: none;	
	padding: 0px 0px 5px 0px;
	display: inline-block;
}

#nav a:hover {
	color: #ffffff;
	text-decoration: none;	
	border-bottom: 3px solid #0082c8;
}

#nav a.selected {
	border-bottom: 3px solid #0082c8;
	font-weight: bold;
}	

#nav .highlight {
	background: #ffffff;
	padding: 2px 0 2px 0;
	margin-left: 5px;
}

#nav .highlight a {
	color: #024B93;
	padding: 2px 15px 2px 15px;
	
}


.content {
	padding: 80px 165px 80px 165px;
	/*background-image:url(images/contentbg.jpg);*/
}

.blue-box {
	padding: 20px 40px 40px 60px;	
	color: #fff;
	background-color: #007ac2;
	height: 285px;
	font-size: 15px;
}

.blue-box img {
	width: 250px;
	margin: 0px 0px 15px 0px;	
}

.transparent-box {
	height: 285px;
	background-size: 100% 100%;	
}

.accreditations {
	background-color: #e3e4e8;
	text-transform: uppercase;
	color: #383e49;
	font-weight: bold;
	font-size: 25px;
	padding: 15px 0px 15px 0px;
	text-align: center;	
}

.location-image {
	background-size: cover;
}

#bottom {
	background-color: #383e49;
	color: #fff;	
	padding: 40px 40px 40px 40px;
	font-size: 16px;
}

#bottom b {
	text-transform: uppercase;
	border-bottom: 1px solid #8f9297;
	display: block;	
	font-size: 21px;
	font-weight: normal;
	padding: 0px 0px 7px 0px;
	margin: 0px 0px 30px 0px;
}

#bottom a {
	color: #fff;
	text-decoration: none;	
}

#bottom a:hover {
	text-decoration: underline;	
}

#bottom .facebook {
	background-image:url(images/facebook.png);
	background-repeat: no-repeat;
	padding: 0px 0px 30px 30px;
}

#bottom .cff-item {
	background-image:url(images/facebook.png);
	background-repeat: no-repeat;
	padding: 0px 0px 30px 30px;
}

#bottom .form-control {
	border-radius: 1px;	
	height: 40px;
}

#bottom .btn-default {
	border-radius: 1px;
	color: #fff;
	background-color: #007ac2;
	border: 1px solid #007ac2;
	text-transform: uppercase;
	font-size: 20px;	
	padding: 4px 20px 4px 20px;
}

#bottom .btn-default:hover {
	background-color: #004b91;	
	border: 1px solid #004b91;
}


#menu-footer-menu {
	padding-left: 0;
	text-transform: uppercase;
	list-style: none;
}

#footer {
	font-size: 16px;	
	padding: 20px 40px 90px 40px;
}

#footer .left {
	
}

#footer .right {
	text-align: right;	
}

#footer a {
	color: #4f4e56;	
	text-decoration: none;
}

#footer a:hover {
	color: #4f4e56;	
	text-decoration: underline;
}

.content-overlay {
	position: absolute; 
	bottom: 0; 
	left: 0; 
	background-color:rgba(7, 4, 76, 0.8);
	color: #fff;
	/*z-index: 1000; */
	width: 100%;
	padding: 35px 165px 35px 165px;
	text-align: center!important;	
}

.sub-content {
	padding: 80px 40px 80px 40px;
	background-color: #f6f7f7;
}

.team-outer {
	padding: 0px 3px 12px 3px;	
}

.team {
	border: 1px solid #bcbdbd;
	padding: 10px;	
	font-size: 14px;
}

.team .left {
	float: left;	
}

.team .right {
	float: right;
}	

.team b {
	text-transform: uppercase;	
}

.member-photo {
	float: left;
	max-width: 200px;
	margin-right: 20px;

}

.member {
	display: inline-block;
}

.member-content {
	overflow: hidden;
}




.contact {
	text-align: left;
	margin: 50px 0px 0px 0px;
}

.contact .left {
	
}

.contact.right {
	padding: 10px 0px 0px 0px;
}

.contact-height {
	height: 345px;
}

.contact .form-control {
	background-color: #e4e7ea;
	border: 1px solid #e4e7ea;
	box-shadow: none;
	-webkit-box-shadow: none;
	height: 45px;
	font-size: 20px;
	text-transform: uppercase;
}

.contact textarea.form-control {
	height: 200px;
	font-size: 20px;
}	

.contact .btn-default {
	text-align: right;
	background-color: #007ac2;
	text-transform: uppercase;
	color: #fff;
	float: right;
	border-radius: 1px;
	border: 1px solid #007ac2;
	font-size: 17px;
	padding: 7px 20px 7px 20px;
}

.contact .btn-default:hover {
	text-decoration: none;
	background-color: #004b91;	
	border: 1px solid #004b91;
}

.contact .blue-link {
	text-align: left;
}	

.contact .blue-link a {
	margin: 0px 0px 0px 0px;
	font-size: 17px;
	padding: 8px 20px 8px 20px;	
}

.contact b {
	color: #0082c8;	
}

.contact-video {
	margin: 55px 0px 0px 0px;
	text-align: center;	
}

.contact-video iframe {
	width: 100%!important;	
}

.contact-locations {
	text-align: center;
	text-transform: uppercase;
	font-size: 23px;
	border-top: 1px solid #959595;
	padding: 25px 0px 25px 0px;	
	color: #007ac2;
}

.contact-locations a {
	color: #9eb4c6;
	text-decoration: none;	
}

.contact-locations a:hover {
	color: #007ac2;
	text-decoration: underline;	
}

.contact-locations a.selected {
	color: #007ac2;
	text-decoration: none;	
}

.contact-locations .grey {
	color: #9eb4c6;
}

.projects {
	/*background-image: url(images/projectsbg.png);	*/
	padding: 80px 60px 80px 60px!important;
}

.projects a {
	text-transform: uppercase;
	color: #58595b;
	text-decoration: none;	
	font-size: 14px;
}

.projects a:hover {
	color: #007ac2;
	text-decoration: underline;	
}

.projects a.selected {
	color: #007ac2;
}

.project-thumb {
	text-align: left;
}

.project-thumb img {
	width: 100%;
}	

a.project-thumb-link {
	background-image: url(images/projectlink.png);
	background-repeat: no-repeat;
	color: #4f4e56;
	text-decoration: none;
	font-size: 17px;
	font-weight: bold;
	padding: 0px 0px 0px 35px;
	display: block;
	margin: 10px 0px 60px 0px;
	height: 50px;
}

a.project-thumb-link:hover {
	color: #258dca;	
	text-decoration: none;
}

.subnav, .sub-menu {
	position: absolute;
	top: 72px;
	left: -75px;
	z-index: 4000;
	text-align: left;
	background-color:rgba(0, 75, 145, 0.7);
	padding: 20px 10px 15px 10px!important;
	width: 270px!important;
	display: none;
}

.subnav li, .sub-menu li {
		display: block!important;
		padding: 0px 20px 5px 20px!important;
		background-image:url(images/subnavbullet.png);
		background-repeat: no-repeat;
	}

.subnav a, .sub-menu a {
	color: #fff!important;
	text-decoration: none;	
	padding: 0px 0px 5px 0px;
	display: inline-block;
}

.subnav a:hover, .sub-menu a:hover {
	color: #cdcdcd!important;
	text-decoration: none;	
	border-bottom: none!important;
}	

.news {
	text-align: left;	
	padding: 80px 125px 80px 125px;
}

.date-cat {
	position: absolute;
	top: -40px;
	left: 0;	
}

.date-cat .date {
	background-color: #0082c8;
	color: #fff;
	display: inline-block;
	padding: 10px 20px 0px 20px;
	margin: 0px;
	font-size: 20px;
	text-transform: uppercase;
}

.date-cat .date span {
	font-size: 50px;
}

.date-cat .cat {
	background-color: #9eb4c6;
	color: #fff;
	display: inline-block;
	padding: 40px 20px 13px 20px;
	margin: 0px;
	font-size: 20px;
	text-transform: uppercase;
}

.date-cat .cat a {
	color: #fff;
	text-decoration: none;	
}

.date-cat .cat a:hover {
	color: #fff;
	text-decoration: underline;	
}

.news-info {
	border-bottom: 1px solid #d7d7d7;
	padding: 15px 0px 15px 0px;	
	font-size: 12px;
	margin: 0px 0px 55px 0px;
}

.news-info ul {
	padding: 0px;
	margin: 0px;	
}

.news-info ul li {
	list-style: none;
	display: inline-block;
	background-repeat: no-repeat;
	padding: 4px 20px 2px 30px;	
}

.news-info .author {
	background-image:url(images/news-author.png);
}

.news-info .comments {
	background-image:url(images/news-comments.png);
}

.news-info .likes {
	background-image:url(images/news-likes.png);
}

.news-info .categories {
	background-image:url(images/news-categories.png);
}

.news-info .tags {
	background-image:url(images/news-tags.png);
}

.news-item {
	margin: 0px 0px 140px 0px;	
}

.news-item h3 {
	text-align: left;
	font-size: 30px;
	font-weight: normal;	
}

.news-item a {
	color: #515151;	
	text-decoration: none;
}

.news-item a:hover {
	color: #515151;
	text-decoration: underline;	
}

.news-item iframe {
	width: 100%;
	height: 400px;	
}

.float-left {
	float: left;	
}

.float-right {
	float: right;	
}

a.continue-reading {
	border: 1px solid #666666;	
	display: block;
	font-size: 13px;
	padding: 12px;
}

a.continue-reading:hover {
	text-decoration: none;	
	background-color: #0082c8;
	border: 1px solid #0082c8;
	color: #fff;
}

.blog-categories {
	border: 1px solid #e2e2e2;
	padding: 25px;
	text-transform: uppercase;	
}

.blog-categories b {
	font-weight: normal;
	margin: 0px 0px 40px 0px;
	display: block;
}	

.blog-categories ul {
	padding: 0px;
	margin: 0px;
}	

.blog-categories li {
	list-style: none;
	border-bottom: 1px solid #e2e2e2;
	padding: 0px 0px 18px 0px;
	margin: 0px 0px 10px 0px;
	font-size: 15px;	
}

.blog-categories li span {
	background-color: #0082c8;
	display: inline-block;
	color: #fff;	
	font-size: 13px;
	padding: 5px 0px 5px 0px;
	margin: 0px 10px 0px 0px;
	width: 40px;
	text-align: center;
}

.blog-categories .count {
	background-color: #0082c8;
	display: inline-block;
	color: #fff;	
	font-size: 13px;
	padding: 5px 0px 5px 0px;
	margin: 0px 10px 5px 0px;
	width: 40px;
	text-align: center;
	float: left;
}

.blog-categories a {
	color: #888888;
	text-decoration: none;	
}

.blog-categories a:hover {
	color: #888888;
	text-decoration: underline;	
}

h3.vacancy-info {
	text-align: left;	
	margin: 0px 0px 0px 0px;
}

.upload-cv {
    border: 1px solid #383e49;
    -webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
    padding: 10px;
    margin: 0px 0px 5px 0px;
    width: 500px;
    font-style: italic;
    color: #fff;
	background-color: #383e49;
	font-size: 16px;
	display: inline-block;
	float: left;
}

.upload-cv-button {
	display: inline-block;	
	float: left;
}

.upload-cv-button.btn-default {
    -webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
    color: #fff;
    background-color: #007ac2;
    border: 1px solid #007ac2;
    text-transform: uppercase;
    font-size: 20px;
    padding: 8px 20px 8px 20px;
}

.upload-cv-button.btn-default:hover {
    background-color: #004b91;
    border: 1px solid #004b91;
}

.upload-files-accepted {
	color: #939598;
	margin: 5px 0px 50px 0px;
}

.project-slider .bxslider2 img {
	width: 100%!important;
}

.project-slider .bx-wrapper {
	width: 100%!important;	
	margin-top: 0px!important;
	margin-bottom: 0px!important;
}

.project-slider ul.bxslider2 {
  margin: 0!important;
  padding: 0!important;
}

.project-slider .bxslider4 li {
	left:0;
}

.project-slider .bx-viewport {
	overflow: visible!important;
	position: static!important;	
}

.project-slider .bx-wrapper .bx-prev {
	left: 50px!important;
	background: url(images/arrow-left2.png) no-repeat;
}

.project-slider .bx-wrapper .bx-next {
	right: 50px!important;
	background: url(images/arrow-right2.png) no-repeat;
}

.project-slider .bx-wrapper .bx-controls-direction a {
	top: 50%;
	width: 46px;
	height: 46px;
}

.content-overlay.project-blue-content {
	position: relative!important; 
	background-color:#0a3054!important;
	text-align: center!important;	
	padding: 35px 165px 10px 165px!important;
}

.content-overlay.project-blue-content h2 {
	line-height: 30px!important;
}

.project-slider .bx-viewport {
	background-color: transparent!important;	
}

.projects-slider .bx-wrapper .bx-prev {
	background: url(images/arrow-left2.png) no-repeat;
	width: 46px;
	height: 46px;
	left: -70px;
}

.projects-slider .bx-wrapper .bx-next {
	background: url(images/arrow-right2.png) no-repeat;
	width: 46px;
	height: 46px;
	right: -70px;
}

 .project-thumb-img {
	 width: 100%; 
	 height: 275px; 
	 background-size:cover;
 }
 
 .project-hero-img {
	  width: 100%; 
	  height: 700px; 
	  background-size:cover;
	  background-repeat:no-repeat;
	  background-position:center;
 }
 
  .project-hero-img2 {
	  width: 100%; 
	  height: 570px; 
	  background-size:cover;
	  background-repeat:no-repeat;
	  background-position:center;
 }
 
 .contact_map iframe {
	width: 100%!important;
 }
 
 .cat-item {
	clear: both!important; 
 }	
 
 .projects ul {
	 display: inline-block;
	 list-style-position: inside;
	 margin: 0px;
	 padding: 0px;
 }
 
 #bookconsultationform {
	background-color: #fff; 
	width: 247px;
	position: absolute;
	top: 40px;
	left: 0;
	z-index: 1000;
 }
 
 #bookconsultationform  .form-control {
	background-color: #fff;
    border: 1px solid #fff;
    height: 43px;
    font-size: 18px;
 }

#bookconsultationform .form-group {
	margin: 0px 0px 0px 0px!important;
}

#bookconsultationform .btn-default {
	width: 100%!important;
	text-align: center!important;
	border: 1px solid #fff;
	border-left: 10px solid #fff;
	border-right: 10px solid #fff;	
	border-bottom: 10px solid #fff;
}

.fixed-header {
	position: fixed; 
	top: 0; 
	left: 0; 
	right: 0; 
	background-color: #024B93; 
	z-index: 50000;	
}

.fixed-height {
	height: 143px;	
}

.custom-pagination {
	clear: both!important;	
}

.custom-pagination .page-numbers {
	display: none!important;
}

.custom-pagination .prev.page-numbers, .custom-pagination .next.page-numbers {
	display: inline-block!important;
	font-size: 20px;
}

.custom-pagination a.page-numbers:hover {
	color: #007ac2;
	text-decoration: none;
}

.cff-share-link, .cff-dot, .cff-date-dot, .cff-more, .cff-less {
	display: none!important;
}






/***** Banner ******/


.bx-pager {
	display: none;
}

#banner-circles-wrapper {
	/*max-width: 1240px;*/
	padding:0px 50px 0px 50px;
	/*padding: 0;*/
	/* margin-left: -15px; */
    /* margin-right: -15px; */
    margin: 0 auto;
   
}

#banner-circles {
	display: block;
	position: relative;
	margin: -90px auto 0 auto;
    text-align: center;
    /*float: left;*/
    width: 100%;
    max-width: 1000px;
}


.hero-outer {
	background: rgba(255, 255, 255, 0.3);
	padding: 15px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	width: 180px;
	height: 180px;
	float: none;	
	box-sizing: border-box;
	display: inline-block;
	position: relative;
	vertical-align: top;
	margin: 0 1%;
}

.hero-circle {
	/*background: #024B93;*/
	background: linear-gradient(-125deg,#0c5b97, #003761);
	color: #ffffff;
	text-transform: uppercase;
	text-align: center;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	width: 150px;
	height: 150px;
	position: relative;
	float: none;

}

.hero-circle:hover {
	background: linear-gradient(-125deg,#007ac2, #003761);
}

.hero-circle .title {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
    font-size: 14px;

}

.hero-circle .link {
	position: absolute;
    bottom: 10px;
    left: 44%;
    margin: 0 auto;
    display: block;
    font-size: 30px;
}

.hero-circle .link a {
	color: #ffffff;
}







.hero-boxone-new, .hero-boxtwo-new, .hero-boxthree-new {	
		/*display: block!important;
		position: relative!important;
		top: 0!important;
		right: 0!important;
		left: 0!important;*/
		margin: 0 auto!important;
		margin-bottom: 10px!important;
		margin-top: 20px!important;
		z-index: 5000!important;
		width: 250px!important;
	}
	
	
	
.hero-boxone-new .title {
	background-color:rgba(7, 4, 76, 0.8);
	color: #fff;
	padding: 10px 15px 12px 15px; 
	font-size: 16px;
}

.hero-boxone-new .title b {
	font-size: 13px;
	display: block;
	text-transform: uppercase;
	margin: 0px 0px 5px 0px;
}

.hero-boxone-new .link {
	background-color:rgba(2, 7, 40, 0.9);
	color: #fff;
	padding: 5px 15px 5px 15px; 
	font-size: 16px;
	text-transform: uppercase;
}

.hero-boxtwo-new .title {
	background-color:rgba(0, 75, 145, 0.7);
	color: #fff;
	padding: 10px 15px 12px 15px; 
	font-size: 15px;
}

.hero-boxtwo-new .title b {
	font-size: 13px;
	display: block;
	text-transform: uppercase;
	margin: 0px 0px 5px 0px;
}

.hero-boxtwo-new .link {
	background-color:rgba(17, 35, 99, 0.9);
	color: #fff;
	padding: 5px 15px 5px 15px; 
	font-size: 16px;
	text-transform: uppercase;
}

.hero-boxthree-new .title {
	background-color:rgba(50, 48, 44, 0.7);
	color: #fff;
	padding: 10px 15px 12px 15px; 
	font-size: 15px;
}

.hero-boxthree-new .title b {
	font-size: 13px;
	display: block;
	text-transform: uppercase;
	margin: 0px 0px 5px 0px;
}

.hero-boxthree-new .link {
	background-color:rgba(27, 30, 29, 0.9);
	color: #fff;
	padding: 5px 15px 5px 15px; 
	font-size: 16px;
	text-transform: uppercase;
}

.hero-boxone-new .link a, .hero-boxtwo-new .link a, .hero-boxthree-new .link a {
	color: #fff;
	text-decoration: none;	
	font-size: 13px;
}

.hero-boxone-new .link a:hover, .hero-boxtwo-new .link a:hover, .hero-boxthree-new .link a:hover {
	text-decoration: underline;	
}




@media  (max-width: 1200px) {
	body {
		font-size: 16px;
	}
	
	#logo {
		padding: 20px 0px 15px 20px;	
	}
	
	#nav {
		padding: 40px 20px 0px 0px; 
		font-size: 14px;
	}	
	
	h1 {
		font-size: 26px;	
	}
	
	h2 {
		font-size: 17px;
	}
	
	h3 {
		font-size: 17px;
	}
		
	.circle {
		padding: 0px 0px 30px 0px;	
	}
	
	.content {
		padding: 60px 145px 60px 145px;
	}
	
	.news {	
		padding: 60px 85px 60px 85px;
	}
	
	.sub-content {
		padding: 60px 10px 60px 10px;
	}
	
	.team {
		padding: 7px;	
		font-size: 12px;
	}
	
	.content-overlay {
		padding: 25px 145px 25px 145px;	
	}
	
	.content-overlay.project-blue-content {
		padding: 35px 145px 10px 145px!important;
	}
	
	.blue-link a {
		font-size: 18px;
		margin:  60px 0px 0px 0px;
	}
	
	.blue-box {
		padding: 20px 20px 20px 20px;	
		height: 255px;
		font-size: 14px;
	}
	
	.blue-box img {
		width: 180px;
		margin: 0px 0px 10px 0px;	
	}
	
	.transparent-box {
		height: 255px;
	}
	
	.accreditations {
		font-size: 21px;
		padding: 13px 0px 13px 0px;
	}
		
	#bottom {
		padding: 20px 10px 20px 10px;
		font-size: 13px;
	}
	
	#bottom b {
		font-size: 18px;
		margin: 0px 0px 20px 0px;
	}
	
	#bottom .form-control {	
		height: 35px;
	}
	
	#bottom .btn-default {
		font-size: 16px;	
		padding: 3px 15px 3px 15px;
	}	
	
	#footer {
		font-size: 14px;	
		padding: 20px 10px 50px 10px;
	}	
	
	.contact .form-control {
		font-size: 18px;
	}
	
	.contact textarea.form-control {
		font-size: 18px;
	}	
	
	.contact .btn-default {
		font-size: 14px;
		padding: 7px 10px 7px 10px;
	}	
	
	.contact .blue-link a {
		font-size: 14px;
		padding: 8px 10px 8px 10px;	
	}

	.contact-locations {
		font-size: 21px;
		padding: 20px 0px 20px 0px;	
	}
	
	.projects {
		padding: 80px 10px 80px 10px!important;
	}
	
	.projects a {	
		font-size: 13px;
	}
	
	a.project-thumb-link {
		font-size: 14px;
		margin: 10px 0px 30px 0px;
	}
	
	.subnav {
		top: 83px;
		left: -75px;
		background-color:rgba(0, 75, 145, 0.8);
		width: 250px!important;
	}
		
	.date-cat {
		top: -30px;	
	}
	
	.date-cat .date {
		padding: 10px 15px 0px 15px;
		font-size: 18px;
	}
	
	.date-cat .date span {
		font-size: 45px;
	}
	
	.date-cat .cat {
		padding: 38px 15px 11px 15px;
		font-size: 18px;
	}
	
	.news-info {
		font-size: 11px;
		margin: 0px 0px 35px 0px;
	}
	
	.news-info ul li {
		padding: 6px 15px 6px 25px;	
	}
	
	.news-item {
		margin: 0px 0px 100px 0px;	
	}
	
	.news-item h3 {
		font-size: 28px;	
	}	
	
	.news-item iframe {
		height: 330px;	
	}
	
	a.continue-reading {
		font-size: 12px;
		padding: 10px;
	}
	
	.blog-categories li {
		font-size: 14px;	
	}
	
	.blog-categories li span {
		font-size: 12px;
	}
	
	.upload-cv {
		padding: 8px;
		width: 400px;
		font-size: 15px;
	}	

	.upload-cv-button.btn-default {
		font-size: 18px;
		padding: 7px 20px 7px 20px;
	}
	
	.project-thumb-img {
		 height: 205px; 
	 }
	 
	 #bookconsultationform {
		width: 190px;
		top: 35px;
	 }
 
	 #bookconsultationform  .form-control {
		height: 35px;
		font-size: 15px;
	 }	
	 
	 .fixed-height {
		height: 124px;	
	}
}

@media  (max-width: 992px)  {
	body {
		font-size: 15px;
	}
			
	#nav {
		/*display: none;*/
		background-color: #e3e4e8;
		padding: 10px 0px 10px 0px;
		text-align: center;
	}
	
	#nav ul li {
		display: inline;
		padding: 0px 5px 0px 5px;	
	}
	
	#nav a {	
		padding: 8px 0px 5px 0px;
		color: #4f4e56;
	}

	#nav a:hover {
		color: #4f4e56;
	}

	#nav .highlight {
		background: #024B93;
	}

	#nav .highlight a {
		color: #e3e4e8;
	}

	#logo {
		text-align: center;	
		margin: 0 auto;
		width: 300px;
		padding: 15px 0px 15px 0px;
	}
	
	h1 {
		font-size: 26px;	
	}
	
	/*h2 {
		font-size: 17px;
	}
	
	h3 {
		font-size: 17px;
	}*/
	
	.circle {
		padding: 0px 0px 25px 0px;	
	}
	

	.mobile-banner {
		float: left;
		height: auto;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.content {
		padding: 40px 85px 40px 85px;
	}
	
	.sub-content {
		padding: 40px 85px 40px 85px;
	}
	
	.content-overlay {
		padding: 15px 85px 15px 85px;	
	}
	
	.content-overlay.project-blue-content {
		padding: 35px 85px 10px 85px!important;
	}
	
	.blue-link a {
		font-size: 16px;
		margin:  40px 0px 0px 0px;
	}
	
	.blue-box {
		padding: 12px 12px 12px 12px;	
		height: 220px;
		font-size: 12px;
	}
	
	.blue-box img {
		width: 150px;
		margin: 0px 0px 5px 0px;	
	}
	
	.blue-box b {
		font-weight: normal;	
	}
	
	.transparent-box {
		height: 220px;
	}
	
	.accreditations {
		font-size: 18px;
		padding: 11px 0px 11px 0px;
	}
	
	#bottom {
		padding: 10px 10px 20px 10px;
		font-size: 12px;
	}
	
	#bottom b {
		font-size: 16px;
		margin: 10px 0px 10px 0px;
	}
	
	#bottom .form-control {	
		height: 30px;
	}
	
	#bottom .btn-default {
		font-size: 14px;	
	}
	
	#footer {
		font-size: 11px;	
		padding: 20px 10px 20px 10px;
	}
	
	#footer .right {
		text-align: left;	
	}
	
	.contact .form-control {
		font-size: 16px;
	}
	
	.contact textarea.form-control {
		font-size: 16px;
	}
	
	.contact-height {
		height: auto;
		margin: 0px 0px 20px 0px;
	}
	
	.contact.left {
		margin: 0px 0px 20px 0px;	
	}
	
	.contact-locations {
		font-size: 17px;
		padding: 15px 0px 15px 0px;	
	}
	
	.projects {
		padding: 80px 30px 80px 30px!important;
	}
	
	.projects a {	
		font-size: 12px;
	}
	
	a.project-thumb-link {
		font-size: 12px;
		margin: 10px 0px 30px 0px;
	}
	
	.subnav {
		top: 37px;
		left: -75px;
		background-color:rgba(0, 75, 145, 0.9);
		width: 240px!important;
	}
	
	.subnav li {
		display: block!important;
		padding: 0px 20px 5px 20px!important;
	}
	
	.subnav a {
		padding: 0px 0px 5px 0px!important;	
	}
	
	.date-cat {
		top: -20px;	
	}
	
	.date-cat .date {
		padding: 10px 15px 0px 15px;
		font-size: 16px;
	}
	
	.date-cat .date span {
		font-size: 40px;
	}
	
	.date-cat .cat {
		padding: 34px 15px 11px 15px;
		font-size: 16px;
	}
	
	.news-info {
		font-size: 11px;
		margin: 0px 0px 35px 0px;
	}
	
	.news-info ul li {
		padding: 6px 15px 6px 25px;	
	}
	
	.news-item {
		margin: 0px 0px 100px 0px;	
	}
	
	.news-item h3 {
		font-size: 25px;	
	}	
	
	.news-item iframe {
		height: 330px;	
	}
	
	a.continue-reading {
		font-size: 12px;
		padding: 10px;
	}
	
	.upload-cv {
		padding: 6px;
		width: 300px;
		font-size: 14px;
	}	

	.upload-cv-button.btn-default {
		font-size: 16px;
		padding: 6px 15px 6px 15px;
	}
	
	.project-thumb-img {
		 height: 145px; 
	 }
	 
	  .contact_map iframe {
		height: 300px!important;
	 }
	 
	 .fixed-height {
		height: 163px;	
	}
}

@media  (max-width: 767px)  {		
	body {
		font-size: 14px;
	}
	
	h1 {
		font-size: 24px;	
	}
	
	h2 {
		font-size: 15px;
	}
	
	h3 {
		font-size: 15px;
	}
	
	.content {
		padding: 30px 55px 30px 55px;
	}
	
	.sub-content {
		padding: 30px 55px 30px 55px;
	}
	
	.content-overlay {
		padding: 25px 55px 20px 55px;
		position: relative;	
		background-color: #092949;
	}
	
	.content-overlay.project-blue-content {
		padding: 35px 55px 10px 55px!important;
	}
	
	.blue-link a {
		font-size: 15px;
		margin:  30px 0px 0px 0px;
	}
	
	.blue-box {
		padding: 20px 20px 20px 20px;	
		height: auto;
		font-size: 13px;
	}
	
	.blue-box img {
		width: 190px;
		margin: 0px 0px 10px 0px;	
	}
	
	.blue-box b {
		font-weight: bold;	
	}
	
	.transparent-box {
		display: none;
	}
	
	.accreditations {
		font-size: 16px;
		padding: 11px 0px 11px 0px;
	}
	
	#bottom b {
		margin: 20px 0px 10px 0px;
	}
	
	#bottom .facebook, #bottom .cff-item {
		padding: 0px 0px 0px 30px;
		margin: 15px 0px 0px 0px;
	}
	
	#accreditations {
		display: none!important;	
	}
	
	.team .left {
		float: none;	
	}
	
	.team .right {
		float: none;
		margin: 10px 0px 0px 0px;
	}	
	
	.contact .form-control {
		font-size: 15px;
	}
	
	.contact textarea.form-control {
		font-size: 15px;
	}
	
	.contact-locations {
		font-size: 15px;
		padding: 15px 0px 15px 0px;	
	}
	
	/*.subnav {
		top: 37px;
		left: -75px;
		background-color:rgba(0, 75, 145, 0.9);
		width: 240px!important;
	}
	
	.subnav li {
		display: block!important;
		padding: 0px 20px 5px 20px!important;
	}
	
	.subnav a {
		padding: 0px 0px 5px 0px!important;	
	}*/
	
	.date-cat .date {
		padding: 10px 10px 0px 10px;
		font-size: 14px;
	}
	
	.date-cat .date span {
		font-size: 30px;
	}
	
	.date-cat .cat {
		padding: 26px 10px 6px 10px;
		font-size: 14px;
	}
	
	.news-item h3 {
		font-size: 22px;	
	}	
	
	.news-item iframe {
		height: 230px;	
	}
	
	a.continue-reading {
		margin: 20px 0px 0px 0px;;
	}
	
	.upload-cv {
		width: 190px;
		font-size: 13px;
	}	

	.upload-cv-button.btn-default {
		font-size: 14px;
		padding: 7px 10px 6px 10px;
	}
	
	.project-slider .bx-wrapper .bx-prev {
		background-size: cover;
	}
	
	.project-slider .bx-wrapper .bx-next {
		background-size: cover;
	}
	
	.project-slider .bx-wrapper .bx-controls-direction a {
		width: 30px;
		height: 30px;
	}
	
	.project-thumb-img {
		 height: 225px; 
		 width: 50%;
	 }
	
	.fixed-height {
		height: 206px;	
	}
	
	#nav ul {
		width: 300px;
		margin: 0 auto;
	}
	#nav a {

	}
	#banner-circles-wrapper {
		padding:0px 10px 0px 10px;
	}

	#banner-circles {
		margin: 0 auto 0 auto;
	}
	.hero-outer {
		margin-bottom: 10px;
	}

	
}


@media  (max-width: 620px)  {


}

@media  (max-width: 620px)  {


}


@media  (max-width: 480px)  {
	body {
		font-size: 13px;
	}
	
	h1 {
		font-size: 20px;	
	}
	
	h2 {
		font-size: 14px;
	}
	
	h3 {
		font-size: 14px;
	}
	
	.content {
		padding: 30px 35px 30px 35px;
	}
	
	.sub-content {
		padding: 30px 35px 30px 35px;
	}
	
	.content-overlay {
		padding: 25px 35px 20px 35px;	
	}
	
	.blue-link a {
		font-size: 13px;
		margin:  25px 0px 0px 0px;
	}
	
	.accreditations {
		font-size: 14px;
		padding: 10px 0px 10px 0px;
	}
	
	.contact-locations {
		font-size: 14px;
		padding: 10px 0px 10px 0px;	
	}
	
	.subnav {
		top: 70px;
		right: 0;
		background-color:rgba(0, 75, 145, 0.9);
		width: 100%;
	}
	
	.subnav li {
		display: block!important;
		padding: 0px 20px 5px 20px!important;
	}
	
	.subnav a {
		padding: 0px 0px 5px 0px!important;	
	}
	
	.project-thumb-img {
		 height: 225px; 
		 width: 100%;
	 }
	
	 .member {
	 	width: 100%;
	 }

	.member-photo {
		float: none;
		width: 100%;
		max-width: none;
		margin-bottom: 20px;
	}



	}