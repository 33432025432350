@charset "utf-8";
/* CSS Document */

/* The animation code */
@keyframes example {
	0% {background-image:url(images/herocareers.png);}
    50% {background-image:url(images/heroprofessional.png);}
    100% {background-image:url(images/heroresults.png);}
}

/* The element to apply the animation to */
div.example {
    width: 100%;
    height: 570px;
	background-size: cover;
	
    animation-name: example;
    animation-duration: 10s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-timing-function:steps(1); 
		
	-webkit-animation-name: example;
    -webkit-animation-duration: 10s;
	-webkit-animation-iteration-count: 1;
	-webkit-animation-fill-mode: forwards;
	-webkit-animation-timing-function:steps(1);	
}

.jppoverlay {
	position: absolute; 
	top: 160px; 
	left: 0; 
	right: 0; 
	margin: 0 auto; 
	width: 175px;
}

.jppoverlay img {
	width: 175px;
	height: 175px;
}

/* The animation code */
@keyframes boxone {
	from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes boxtwo {
	from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes boxthree {
	from { opacity: 0; }
      to { opacity: 1; }
}

.hero-boxone {
	/*animation-name: boxone;
	animation-duration: 2s;	
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-timing-function:steps(1); 
		
	-webkit-animation-name: boxone;
    -webkit-animation-duration: 2s;
	-webkit-animation-iteration-count: 1;
	-webkit-animation-fill-mode: forwards;
	-webkit-animation-timing-function:steps(1);	*/
	
	width: 265px;
	position: absolute;
	top: 380px;
	left: 16%;
}

.hero-boxtwo {
	/*animation-name: boxtwo;
	animation-duration: 2s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-timing-function:steps(1); 
		
	-webkit-animation-name: boxtwo;
    -webkit-animation-duration: 2s;
	-webkit-animation-iteration-count: 1;
	-webkit-animation-fill-mode: forwards;
	-webkit-animation-timing-function:steps(1);*/	
	
	width: 265px;
	position: absolute;
	top: 380px;
	left: 0;
	right: 0;
	margin: 0 auto;
}

.hero-boxthree {
	/*animation-name: boxthree;
	animation-duration: 2s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-timing-function:steps(1); 
		
	-webkit-animation-name: boxthree;
    -webkit-animation-duration: 2s;
	-webkit-animation-iteration-count: 1;
	-webkit-animation-fill-mode: forwards;
	-webkit-animation-timing-function:steps(1);	*/
	
	width: 265px;
	position: absolute;
	top: 380px;
	right: 16%;
}

.hero-boxone .title {
	background-color:rgba(7, 4, 76, 0.8);
	color: #fff;
	padding: 10px 15px 12px 15px; 
	font-size: 15px;
}

.hero-boxone .title b {
	font-size: 19px;
	display: block;
	text-transform: uppercase;
	margin: 0px 0px 5px 0px;
}

.hero-boxone .link {
	background-color:rgba(2, 7, 40, 0.9);
	color: #fff;
	padding: 5px 15px 5px 15px; 
	font-size: 15px;
	text-transform: uppercase;
}

.hero-boxtwo .title {
	background-color:rgba(0, 75, 145, 0.7);
	color: #fff;
	padding: 10px 15px 12px 15px; 
	font-size: 15px;
}

.hero-boxtwo .title b {
	font-size: 19px;
	display: block;
	text-transform: uppercase;
	margin: 0px 0px 5px 0px;
}

.hero-boxtwo .link {
	background-color:rgba(17, 35, 99, 0.9);
	color: #fff;
	padding: 5px 15px 5px 15px; 
	font-size: 15px;
	text-transform: uppercase;
}

.hero-boxthree .title {
	background-color:rgba(50, 48, 44, 0.7);
	color: #fff;
	padding: 10px 15px 12px 15px; 
	font-size: 15px;
}

.hero-boxthree .title b {
	font-size: 19px;
	display: block;
	text-transform: uppercase;
	margin: 0px 0px 5px 0px;
}

.hero-boxthree .link {
	background-color:rgba(27, 30, 29, 0.9);
	color: #fff;
	padding: 5px 15px 5px 15px; 
	font-size: 15px;
	text-transform: uppercase;
}

.hero-boxone .link a, .hero-boxtwo .link a, .hero-boxthree .link a {
	color: #fff;
	text-decoration: none;	
}

.hero-boxone .link a:hover, .hero-boxtwo .link a:hover, .hero-boxthree .link a:hover {
	text-decoration: underline;	
}

@media  (max-width: 1200px) {
	div.example {
    	height: 470px;
	}
	
	.project-hero-img2 {
		height: 470px;	
	}
	
	.bx-wrapper .bx-pager,
	.bx-wrapper .bx-controls-auto {
		bottom: 20px!important;
	}
	
	.jppoverlay {
		top: 125px; 
		width: 145px;
	}
	
	.jppoverlay img {
		width: 145px;
		height: 145px;
	}
	
	.hero-boxone {		
		width: 220px;
		position: absolute;
		top: 310px;
		/*left: 145px;*/
	}
	
	.hero-boxtwo {	
		width: 220px;
		position: absolute;
		top: 310px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	.hero-boxthree {		
		width: 220px;
		position: absolute;
		top: 310px;
		/*left: 605px;*/
	}
	
	.hero-boxone .title, .hero-boxtwo .title, .hero-boxthree .title {
		font-size: 14px;
	}
	
	.hero-boxone .title b, .hero-boxtwo .title b, .hero-boxthree .title b {
		font-size: 17px;
	}
	
	.hero-boxone .link, .hero-boxtwo .link, .hero-boxthree .link {
		font-size: 14px;
	}	
}

@media  (max-width: 992px)  {
	div.example {
    	height: 363px;
	}
	
	.project-hero-img2 {
		height: 363px;	
	}
	
	.bx-wrapper .bx-pager,
	.bx-wrapper .bx-controls-auto {
		bottom: 0px!important;
	}
	
	.bx-wrapper .bx-pager.bx-default-pager a {
		width: 10px!important;
		height: 10px!important;
		-moz-border-radius: 5px!important;
		-webkit-border-radius: 5px!important;
		border-radius: 5px!important;
	}
		
	.jppoverlay {
		top: 100px; 
		width: 115px;
	}
	
	.jppoverlay img {
		width: 115px;
		height: 115px;
	}
	
	.hero-boxone {		
		width: 180px;
		position: absolute;
		top: 240px;
		left: 14%;
	}
	
	.hero-boxtwo {	
		width: 180px;
		position: absolute;
		top: 240px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	.hero-boxthree {		
		width: 180px;
		position: absolute;
		top: 240px;
		right: 14%;
	}
	
	.hero-boxone .title, .hero-boxtwo .title, .hero-boxthree .title {
		font-size: 13px;
	}
	
	.hero-boxone .title b, .hero-boxtwo .title b, .hero-boxthree .title b {
		font-size: 16px;
	}
	
	.hero-boxone .link, .hero-boxtwo .link, .hero-boxthree .link {
		font-size: 13px;	
	}
}

@media  (max-width: 767px)  {
	div.example {
    	display: none;
	}
	
	#home-slider {
		display: none;	
	}
	
	.jppoverlay {
		display: none;
	}
	
	.hero-boxone {		
		display: none;
	}
	
	.hero-boxtwo {	
		display: none;
	}

	.hero-boxthree {		
		display: none;
	}
	
	
}