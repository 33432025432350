// About
.group-section {
    /*display: none;*/ 
}



 @media screen and (min-width: 768px) {
  .left-offset-16:first-child {
        margin-left: 16.66666667%
    }
}


 @media screen and (min-width: 768px) {
  .left-offset-33:first-child {
        margin-left: 33.33%
    }
}

.team-section {
    background: $white;
    width: 100%;
    margin: 0 auto;
    padding: 60px 0; 
    @media screen and (min-width: 641px) {
        /*width: 80%;*/
    }
    @media screen and (min-width: 1025px) {
        width: 80%;
        /*max-width: 1015px;*/
    }
    .team-inner {
     
            background: url('../images/team-bg.png') top center repeat-y;
        
        h3 {
            max-width: 700px;
            background: $color-panel-primary;
            background-image: url('../images/expand-closed.png');
            background-repeat: no-repeat;
            background-position: right 10px center;
            margin: 10px auto 0px;
            padding: 10px 20px;
            color: $white;
            cursor: pointer;
            &.open {
                background-image: url('../images/expand-open.png');
            }
        }
        .team-outer {
            padding: 30px 0px 0px 0px;
            @media screen and (min-width: 641px) {
                padding: 30px 20px 0px 20px;
            }
            .team {
                height: auto;
                overflow: hidden;
                padding: 0; 
                /*background: #F6F7F7;*/
                background: $white; 
                border: 2px solid $color-dark-blue;
                @media screen and (min-width: 768px) {
                    height: 150px;
                }
                .member {
                    display: block;
                   
                    .profile-section {
                        
                        float: left;
                        @media screen and (min-width: 641px) {
                            /*width: 45%;*/
                        }
                        &.photo-section {                          
                            width: 180px;
                            padding:  0 10px;
                            margin-top: 10px; 
                            height: 130px;
                            /*border: 1px solid #000;*/
                            overflow: hidden;
                            img {
                                width: 100%;
                                height: auto;
                                max-width: 100%;
                                background: #cccccc;
                                position: relative;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                            @media screen and (min-width: 768px) {
                                width: 45%;
                            }
                        }
                        &.bio-section {
                            width: 50%;
                            height: 150px;
                            /*text-align: center;*/
                            padding: 10px;
                            max-width: 50%;
                            @media screen and (min-width: 768px) {
                                text-align: left;
                                width: 55%;
                            }
                            .member-content {
                                height: 130px;
                                position: relative;
                                img {
                                    margin-right: 10px;
                                }
                                .director-name {
                                    text-transform: uppercase;
                                    color: $color-dark-blue;                                    
                                }
                                .qualifications {
                                    margin-top: 5px;
                                    display: block;
                                    /*max-width: 80%;*/
                                }                            

                            }
                            .social-links {
                                position: absolute;
                                bottom: 0;
                                /*border: 1px solid #FF0000;*/

                            }
                        }

                    }
                    .mobile-content {
                        display: none;
                        clear: both;
                        padding: 10px;
                        @media screen and (min-width: 768px) {
                            display: none !important;
                        }
                        .button {
                            background: $color-dark-blue;
                            border-radius: 20px;
                            padding: 5px 50px;
                            color: $white;
                            width: 100%;
                            float: left;
                            margin-bottom: 10px;
                            text-align: center;
                        }
                    }                      
                }
            }
        }
    }
}

.show {
    display: block;

}


.modal-backdrop.in {
    opacity: 0.7;
}

.modal {
    top: 160px;
    padding: 10px;
    h3 {
        background-image: url('../images/expand-close.png') !important;
    }

    .modal-dialog {
        width: 80%;
        @media screen and (min-width: 641px) {
            width: 80%;
        }
        @media screen and (max-width: 767px) {
            //hide modal
            display: none !important;
        }
        @media screen and (min-width: 1025px) {
            width: 70%;
            max-width: 1015px;
        }
        .left-button {
            position: absolute;
            left: -50px;
            top: 45%;
            background: $color-dark-blue;
            color: $white;
            border: 0;
        }
        .right-button {
            position: absolute;
            right: -50px;
            top: 45%;
            background: $color-dark-blue;
            color: $white;
            border: 0;
        }
    }
    .modal-content {
        border-radius: 0;
        overflow: hidden;
        padding: 0 0 10px 0;
        border: 2px solid $color-dark-blue;
        .photo-section {
            img {
                background: #dddddd;
            }
        }

        .bio-section {
            .member-content {
                padding-bottom: 10px;
                span.title {
                    text-transform: uppercase;
                    color: $color-dark-blue;
                    text-align: left;
                }
                span.qualifications {
                    color: $color-panel-primary;
                }
                p {
                    font-size: 14px;
                }
                .button {
                    background: $color-dark-blue;
                    border-radius: 20px;
                    padding: 5px 50px;
                    color: $white;
                }
            }
        }
    }
    .social-links-modal {
        width: 40%;
        float: right;
        text-align: right;
        a {
            margin-left: 10px;
        }
    }
}


/* Hide a modal below a specific width */
@media (max-width: 767px) {
    .modal-backdrop { 
        display: none !important;
    }
    .modal {
        display: none !important;
    }
    body.modal-open {
        overflow: auto;
    }
}



