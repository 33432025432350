// Contact
.privacy-accept {
    margin-top: 10px;
   input[type=checkbox] {
    float: left;
    margin: 5px 10px 0 0;
   }
}






.contact-form {
   
    ::-webkit-input-placeholder { /* WebKit browsers */
        color: #8e8e8e !important;
        opacity: 1;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: #8e8e8e !important;
        opacity: 1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: #8e8e8e !important;
        opacity: 1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10+ */
        color: #8e8e8e !important;
        opacity: 1;
    }
    
    [type=checkbox] {
        margin-bottom: 5px;
        margin-right: 10px;
    }

    select {
        -webkit-border-radius: 13px;
        border-radius: 13px;
        margin-top: 10px;
    }

    [type=email], [type=tel], [type=text], [type=week], textarea {
       
        margin-top: rem-calc(10);
        background: #e0e3e7;
        border: 0px;
         -webkit-border-radius: 6px;
        border-radius: 6px;
        width: 100%;
        padding: 15px;
    }

}



.wpcf7 {

    .wpcf7-list-item.first {
       
    }
    .wpcf7-response-output {
        clear: both;
    }

    .wpcf7-list-item {
    	margin-left: 0;
    }
    .left {
    	float: left;
    	 @media (max-width: 575.98px) {
    	 	width: 100%;
    	 }
    }
    .right {
		float: right;
		@media (max-width: 575.98px) {
    	 	width: 100%;
    	 	margin-top: 20px;
    	 }
    }

    .button {
        float: right;
        margin-top: 0;
        background-color: #024B93;
        color: #FFFFFF;
        font-size: 18px;
        padding: 10px 20px;
        
    }
    
}